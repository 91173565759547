import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading1 } from '../../atoms/Headings/Headings';
import Text from '../../atoms/Text/Text';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import PageWrapper from '../PageWrapper/PageWrapper';
import Image from '../../atoms/Image/Image';

const SuiteBenefitsBanner = ({ heading, subheading, suiteType }) => {
  const sectionCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${uc('180px 0')};

    @media (${breakpoints.tablet}) {
      flex-wrap: wrap;
      min-height: ${uc('750px')};
      padding: ${uc('180px 0 20px 0')};
    }
  `;

  const bannerBackgroundCss = css`
    overflow: hidden;
    background: url('/suiteBanners/suiteBenefitsHeader.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: ${pageDimensions.largeDesktopWidth};
  `;

  const avatarImageCss = css`
    width: ${uc('100px')};
    margin-bottom: ${uc('30px')};
  `;

  // style p to look like h1 for SEO optimization
  const headingCss = css`
    max-width: ${uc('1200px')};
    color: ${colors.darkGray.two};
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.thirty};
    font-family: ${fontFamilies.proximaSoft};
    line-height: ${lineHeights.copy};
    letter-spacing: ${uc('1px')};
    text-align: center;

    @media (${breakpoints.largeTablet}) {
      flex-wrap: wrap;
      text-align: center;
    }

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.thirtyTwo};
      white-space: normal;
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.twentyEight};
    }
  `;

  // style h1 to look like p for SEO optimization
  const textCss = css`
    max-width: ${uc('630px')};
    color: ${colors.darkGray.two};
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.seventeen};
    font-family: ${fontFamilies.roboto};
    line-height: ${lineHeights.copy};
    letter-spacing: 0;
    text-align: center;

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.seventeen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.seventeen};
    }
  `;

  return (
    <section css={bannerBackgroundCss}>
      <PageWrapper css={sectionCss}>
        <Image
          css={avatarImageCss}
          src={`/suiteAvatars/${suiteType}Avatar.svg`}
        />
        {/* use p styled to look like h1 for SEO optimization */}
        <Text css={headingCss}>{heading}</Text>
        {/* use h1 styled to look like p for SEO optimization */}
        <Heading1 css={textCss}>{subheading}</Heading1>
      </PageWrapper>
    </section>
  );
};

SuiteBenefitsBanner.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  suiteType: PropTypes.string,
};

SuiteBenefitsBanner.defaultProps = {
  heading: '',
  subheading: '',
  suiteType: 'learning',
};

export default SuiteBenefitsBanner;
